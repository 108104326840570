<template>
  <card class="p-3 col-lg-8 bg-secondary">
    <h1 class="page-header mb-lg-3 card-title">Create your account</h1>
    <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
      <form class="needs-validation" @submit.prevent="validate().then(submit)">
        <div class="row d-flex justify-content-center">
          <div class="mx-3">
            <b-row class="mb-4">
              <b-col lg="12">
                <base-input
                  v-model="name"
                  alternative
                  group
                  name="full name"
                  placeholder="Full Name"
                  rules="required|alpha_spaces"
                  type="text"
                  formGroupClasses="mb-0"
                ></base-input>
              </b-col>
              <b-col lg="12">
                <base-input
                  v-model="email"
                  alternative
                  group
                  name="email"
                  placeholder="Email Address"
                  rules="required|email"
                  type="email"
                  formGroupClasses="mb-0"
                ></base-input>
              </b-col>
              <b-col lg="12">
                <base-input
                  ref="password"
                  v-model="password"
                  :rules="{ required: true, regex: passwordRegex }"
                  :type="show_password ? 'text' : 'password'"
                  alternative
                  autocomplete="off"
                  group
                  name="password"
                  placeholder="Password"
                  @input="checkPassword"
                  formGroupClasses="mb-0"
                >
                  <template slot="append">
                    <i :class="show_password === true ? 'fas fa-eye-slash' : 'fas fa-eye'"
                       aria-hidden="true"
                       class="eye-icon" @click="show_password = !show_password"></i>
                  </template>
                </base-input>
                <transition name="slide">
                  <div v-show="show_helper" class="password-helper gray-600-color small-text">
                    <b-row>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_twelve_characters }">
                          <input disabled type="checkbox" v-bind:checked="contains_twelve_characters"/>
                          <span>12 Characters</span>
                        </li>
                      </b-col>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_number }">
                          <input disabled type="checkbox" v-bind:checked="contains_number"/>
                          <span>Includes Number</span>
                        </li>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_uppercase }">
                          <input disabled type="checkbox" v-bind:checked="contains_uppercase"/>
                          <span>Includes Uppercase</span>
                        </li>
                      </b-col>
                      <b-col lg="6">
                        <li class="password-rule" v-bind:class="{ 'is-valid': contains_special_character }">
                          <input disabled type="checkbox" v-bind:checked="contains_special_character"/>
                          <span style="margin-left: 5px">Includes Special Character</span>
                        </li>
                      </b-col>
                    </b-row>
                  </div>
                </transition>
              </b-col>
            </b-row>
            <div class="rights-section mb-4 px-1">
              <validate-checkbox
                v-model="terms"
                :rules="{ required: { allowFalse: false } }"
                class="mb-1"
                name="Terms of Service"
              >
                I agree to Keeyora’s
                <a href="https://www.rectxt.com/terms-of-service" target="_blank">
                  <u>Terms of Service</u></a>
              </validate-checkbox>
              <validate-checkbox
                v-model="policy"
                :rules="{ required: { allowFalse: false } }"
                class="mb-1"
                name="Privacy Policy"
              >
                I accept Keeyora’s use of my data for the service and anything
                else described in the
                <a href="https://www.rectxt.com/privacy-policy" target="_blank">
                  <u>Privacy Policy</u></a>
              </validate-checkbox>
            </div>
            <div class="layout-button">
              <div class="form-common-errors small-text mb-4">
                {{ registerFormErrors }}
              </div>
              <button
                :disabled="invalid"
                class="btn btn-outline btn-primary btn-block font-weight-bold"
                type="submit"
              >
                Create Account
              </button>
            </div>
            <div class="d-flex align-items-center flex-column mt-4">
              <p class="mb-0 font-weight-500">Or continue with:</p>
              <google-button/>
              <microsoft-button/>
              <single-sign-on-button/>
            </div>
            <div class="mt-2rem text-center">
              <p class="font-weight-bold">
                Already have an account?
                <router-link :to="{ name: 'Login' }">Sign in</router-link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>

<script>
import {mapActions} from 'vuex';
import {LOGIN_REQUEST} from '/src/store/storeActions';
import ValidateCheckbox from '../../components/App/controls/ValidateCheckbox.vue';
import MicrosoftButton from '@/components/App/User/MicrosoftButton/MicrosoftButton.vue';
import GoogleButton from '@/components/App/User/GoogleButton/GoogleButton.vue';
import SingleSignOnButton from '@/components/App/User/SingleSignOnButton/SingleSignOnButton.vue';

export default {
  components: {
    GoogleButton,
    MicrosoftButton,
    SingleSignOnButton,
    ValidateCheckbox,
  },
  data() {
    return {
      email: '',
      password: '',
      name: '',
      company: '',
      terms: false,
      policy: false,
      validator: null,
      show_password: false,
      show_helper: false,
      password_length: 0,
      contains_twelve_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      // eslint-disable-next-line
      passwordRegex: /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{12,}$/,
    };
  },
  computed: {
    getFormErrors: function() {
      const errors = [];
      if (this.validator) {
        Object.entries(this.validator.errors).forEach(([key, value]) => {
          if (value.length > 0) {
            errors.push(...value);
          }
        });
      }
      return errors;
    },

    registerFormErrors: function() {
      return (this.getFormErrors.length) > 0 ?
        'Please fix the errors' :
        null;
    },
  },
  methods: {
    ...mapActions([
      LOGIN_REQUEST,
    ]),
    async submit() {
      const fullNameArr = this.name.split(' ');
      const data = {
        currentRequest: 'register',
        requestData: {
          email: this.email,
          password: this.password,
          name: this.name,
          firstName: fullNameArr[0],
          lastName: fullNameArr[1],
          company: this.company,
        },
      };
      this.LOGIN_REQUEST(data);
    },
    checkPassword() {
      this.password_length = this.password.length;
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      this.contains_twelve_characters = this.password_length >= 12;
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      this.valid_password = this.contains_twelve_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true;
      this.show_helper = true;
    },
  },
  mounted: function() {
    this.validator = this.$refs.formValidator;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/lbd/mixins/_layout.scss";
@import "@/assets/sass/custom/_app-vars.scss";

.login-logo {
  width: 150px;
}

.grid {
  display: grid;
  height: fit-content;
}

.self-end {
  justify-self: end;
}

.layout-button {
  @include respond-to(sm) {
    width: 100% !important;
  }
  @include respond-to(xs) {
    width: 100% !important;
  }
}

.rights-section a {
  color: $primary-border-color;
}

.eye-icon:hover {
  color: $primary-border-color;
}

.is-valid {
  color: $primary-color;
  font-weight: 600
}

.is-valid:before {
  width: 100%;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.password-rule {
  list-style-type: none; /* Remove bullet points */
  display: flex;
  align-items: center; /* Align items to the center */
}

.password-rule input[type="checkbox"] {
  appearance: none; /* Remove default styles */
  width: 22px; /* Fixed width */
  height: 22px; /* Fixed height */
  margin-right: 4px; /* Margin between checkbox and text */
  display: inline-block;
  position: relative;
  background: none; /* Remove the box background */
  border: none; /* Remove the border */
}

.password-rule input[type="checkbox"]:checked::before {
  content: '✔';
  color: $primary-color;
  font-size: 16px; /* Adjust the font size to fit the checkbox */
  position: absolute;
  top: 25%;
  left: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-rule span {
  line-height: 1.2; /* Adjust line height to align text properly */
}

</style>
